const debug = false

const datos = {
    urlapi: debug === true ? 'http://localhost:5004/api/1.0' : 'https://back.mesval.click/api/1.0',
    key_local_storage_user: 'mz_bknd_user',
    key_local_storage_cart: 'mz_bknd_cart',
    key_local_storage_pedidos: 'mz_bknd_pedidos',
    pagina: 20,
    iva_global: 1.19,
    punto_venta: {
        posId: 1,
        name: "POS API"
    }
}

module.exports = datos