import { useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import data, { key_local_storage_user } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom';
import { acciones } from '../../lib/global/data';
import { showAmmount } from '../../lib/helpers/helpers';
import BuscadorCodigo from '../../subComponents/productos/buscador_codigo';

const ProductosListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ equipos, setEquipos ] = useState([])
    const [ estados, setEstados ] = useState([])
    const [ loadingEsquemaEstados, setloadingEsquemaEstados ] = useState(true)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = props.token ? props.token : false
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ rutaBusqueda, setRutaBusqueda ] = useState('')
    const [ codigoBusqueda, setCodigoBusqueda ] = useState('')
    const [ resultadosCodigoBusqueda, setResultadosCodigoBusqueda ] = useState([])
    const [ loadingCodigos, setLoadingCodigos ] = useState(false)
    const tipo = props.tipo ? props.tipo : "link"
    const abortController = useRef(null)

    const obtenerProductos = async (page, query)=>{
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/productos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }
    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProductos(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div style={{ overflowX: "scroll" }}>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProductos(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtenerEsquemaEstados = () => {
      return fetch(`${data.urlapi}/ordenes/suite-config-estados`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
      .then(pros => pros.json())
      .then(data => { 
          console.log(data)
            if(!data){
            toast.error('Sin datos')
            return setRemoviendo(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return setRemoviendo(false)
            } else if(Array.isArray(data) !== false){
                if(data.length < 1) toast.warn('No hay esquema de estados y subestados')
                setEstados(data)
            }
            return setloadingEsquemaEstados(false)
      })
      .catch(error => {
          toast.error('No pudimos cargar la información')
          setloadingEsquemaEstados(false)
      })
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const accionesBotones = (pro) => {
        if(trash === true) {
            return removiendo === true ? <Spinner animation="border" /> : <div>
            <Button variant="link" size="sm" className='pr-3 pt-0 pb-0 text-danger' onClick={()=>solicitarEliminar(pro._id)}>ELIMINAR DEFINITIVAMENTE</Button>
            <Button variant="link" size="sm" className='pr-3 pt-0 pb-0' onClick={()=>restaurarRegistro(pro._id,'active')}>RESTAURAR</Button>
            </div>
        }
        return removiendo === true ? <Spinner animation="border" /> : <div>
        <Button variant="link" size="sm" className='pr-3 pt-0 pb-0 text-danger' onClick={()=>restaurarRegistro(pro._id,'trash')}>ARCHIVAR</Button>
        </div>
    }

    const seleccionarProducto = (pro) => {
        if(props.onSelectProduct) props.onSelectProduct(pro)
    }

    const showComponentByType = (pro) => {
        switch (tipo) {
            case "funcion":
                return <p className='hover' onClick={() => seleccionarProducto(pro)}><b>{pro.titulo}</b></p>
            default:
                return <Link to={`product-edit-${pro._id}`}>{pro.titulo}</Link>
        }
    }

    const tabla_registros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Table>
                <thead>
                    <th>ID</th>
                    <th>Título</th>
                    <th>Código</th>
                    <th>Código Fábrica</th>
                    <th>Origen</th>
                    <th>Cantidad</th>
                    <th>Cantidad de aplicaciones</th>
                    <th>Precio</th>
                    <th>Acciones</th>
                </thead>
                <tbody>
                    {
                        registros.map(orden => {
                            return <tr key={orden._id} className="mb-3">
                                <th>{orden.id}</th>
                                <th>{showComponentByType(orden)}</th>
                                <th>{orden.codigo}</th>
                                <th>{orden.codigo_fabrica}</th>
                                <th>{orden.origen}</th>
                                <th>{orden.stock}</th>
                                <th>{orden.cantidad_aplicaciones}</th>
                                <th>${showAmmount(orden.precio)}</th>
                                <th>{accionesBotones(orden)}</th>
                            </tr>
                        })
                    }
                    </tbody>
            </Table>
        </div>
    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/productos/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/productos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/productos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/productos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitCodigo = (e) => {
        e.preventDefault()
        setResultadosCodigoBusqueda([])
        if(!codigoBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProductos(1, condicion)
        }
        const string = `^${codigoBusqueda}`
        const condicion = { $or: [
            { codigo: string, status: 'active' },
            { codigo_fabrica: string, status: 'active' }
        ] }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProductos(1, condicion)
    }

    const handleSubmitRuta = (e) => {
        e.preventDefault()
        if(!rutaBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProductos(1, condicion)
        }
        const condicion = { $text: { $search: `\"${rutaBusqueda}\"` }, status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProductos(1, condicion)
    }

    const handleSubmitPatente = (e) => {
        e.preventDefault()
        if(!patente) return toast.error('Selecciona una patente')
        const condicion = { patente: patente.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProductos(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return toast.error('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProductos(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProductos(1, {})
    }

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleChangeRuta = (e) => {
        const { value } = e.target
        return setRutaBusqueda(value)
    }

    const fetchCodigo = (codigo_busqueda) => {
        if(!codigo_busqueda) return false
        abortController.current = new AbortController()
        setLoadingCodigos(true)
        return fetch(`${data.urlapi}/productos/search-code`,{
            signal: abortController.current.signal,
            method: "POST",
            body: JSON.stringify({
                codigo_busqueda: codigo_busqueda.toUpperCase()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then(resultados => {
                if(!resultados){
                    toast.error("Sin datos del servidor")
                } else if(resultados.errorMessage){
                    toast.error(resultados.errorMessage)
                } else if(Array.isArray(resultados) !== false){
                    setResultadosCodigoBusqueda(resultados)
                }
                return setLoadingCodigos(false)
            })
            .catch(error => {
                return setLoadingCodigos(false)
            })
    }

    const cancelRequest = () => abortController.current && abortController.current.abort();

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        if(value){
            if(value.length > 3){
                cancelRequest()
                fetchCodigo(value)
            }
        }
        return setCodigoBusqueda(value)
    }

    const onResultados = (data) => {
        return setEquipos(data)
    }

    const busquedaCodigo = () => {
        if(loadingCodigos) return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            </Card>
        </div>
        return false
        if(resultadosCodigoBusqueda.length < 1) return false

        return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
            {
                resultadosCodigoBusqueda.map(codigo => {
                    return <div key={`${codigo._id}`}>
                        {showComponentByType(codigo)} ${showAmmount(codigo.precio)}
                    </div>
                })
            }
            </Card>
        </div>
    }
 
    return <div>
        {modalFiltro()}
                <Row>
                    <Col md={4}>
                        <form onSubmit={handleSubmitRuta}><input className='mb-3 form-control uppercase' placeholder='BUSCAR POR PALABRA CLAVE' onChange={handleChangeRuta} /></form>
                    </Col>
                    <Col md={4}>
                        <form onSubmit={handleSubmitCodigo}>
                            {busquedaCodigo()}
                            <BuscadorCodigo  onChange={(data) => setCodigoBusqueda(data)} onResultados={(data) => onResultados(data)} />
                            </form>
                    </Col>
                </Row>
                {tabla_registros(equipos)}
    </div>

}

export default ProductosListado