import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-loading-skeleton/dist/skeleton.css'
import Usuarios from './components/Usuarios';
import UsuariosCrear from './components/UsuariosCrear';
import UsuariosEditar from './components/UsuariosEditar';
import Empresas from './components/Empresas';
import EmpresasCrear from './components/EmpresasCrear';
import EmpresasEditar from './components/EmpresasEditar';
import 'react-modern-drawer/dist/index.css'
import Productos from './components/Productos';
import ProductosEditar from './components/ProductosEditar';
import ProductosCrear from './components/ProductosCrear';
import Modelos from './components/Modelos';
import ModelosEditar from './components/ModelosEditar';
import ModelosCrear from './components/ModelosCrear';
import Cilindrada from './components/Cilindrada';
import CilindradaCrear from './components/CilindradaCrear';
import CilindradaEditar from './components/CilindradaEditar';
import MiCuenta from './components/Cuenta'
import Importador from './components/Importador';
import Pedidos from './components/Pedidos';
import EditarPedido from './components/Pedidos/editar';
import PedidoEditar from './components/PedidoEditar';
import Cotizaciones from './components/Cotizaciones';
import EditarCotizacion from './components/Cotizaciones/editar';

require('dotenv').config()

const App = <div>
  <ToastContainer
          position="bottom-right"
          theme="colored"
          />
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/account" component={MiCuenta} />
      <PrivateRoute exact path="/cilindradas" component={Cilindrada} />
      <PrivateRoute exact path="/cilindradas/nuevo" component={CilindradaCrear} />
      <PrivateRoute exact path="/cilindradas-edit-:id" component={CilindradaEditar} />
      <PrivateRoute exact path="/importador" component={Importador} />
      <PrivateRoute exact path="/cotizaciones" component={Cotizaciones} />
      <PrivateRoute exact path="/cotizaciones-edit-:id" component={EditarCotizacion} />
      <PrivateRoute exact path="/modelos" component={Modelos} />
      <PrivateRoute exact path="/pedidos" component={Pedidos} />
      <PrivateRoute exact path="/pedidos-edit-:id" component={PedidoEditar} />
      <PrivateRoute exact path="/productos" component={Productos} />
      <PrivateRoute exact path="/modelos/nuevo" component={ModelosCrear} />
      <PrivateRoute exact path="/model-edit-:id" component={ModelosEditar} />
      <PrivateRoute exact path="/productos/nuevo" component={ProductosCrear} />
      <PrivateRoute exact path="/product-edit-:id" component={ProductosEditar} />
      <PrivateRoute exact path="/usuarios" component={Usuarios} />
      <PrivateRoute exact path="/usuarios/nuevo" component={UsuariosCrear} />
      <PrivateRoute exact path="/usuarios/edit-:id" component={UsuariosEditar} />
      <PrivateRoute exact path="/empresas" component={Empresas} />
      <PrivateRoute exact path="/empresas/nuevo" component={EmpresasCrear} />
      <PrivateRoute exact path="/empresas/edit-:id" component={EmpresasEditar} />
    </Switch>
  </BrowserRouter>
  </Provider>
  </div>

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
